import { Container } from './styles'

export const Input = ({
  type,
  marginTop,
  borderRadius,
  backgroundColor,
  ...props
}: any) => (
  <Container
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    marginTop={marginTop}
    type={type}
    {...props}
  />
)
