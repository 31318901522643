import styled from 'styled-components'
import theme from '../../styles/theme'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const Title = styled.h1<any>`
  font-size: 24px;
  color: ${theme.colors.black};
`

export const Description = styled.p<any>`
  font-size: 16px;
  color: ${theme.colors.black};
  padding: 25px;
`
