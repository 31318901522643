import styled from 'styled-components'
import theme from '../../styles/theme'

export const ContainerButton = styled('button')<any>`
  background: ${(p) => p.color || theme.colors.orange_01};
  color: ${(p) => p.textColor || theme.colors.white};
  font-size: 1rem;
  font-weight: 400;
  width: ${(p) => (p.width ? p.width : '100%')};
  border: 1px solid
    ${(p) => (p.borderColor ? p.borderColor : theme.colors.orange_01)};
  border-radius: 4px;
  padding: ${(p) => p.padding || 10}px;
  cursor: pointer;
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : 16)}px;
  max-width: ${(p) => (p.width ? 'none' : 240)}px;
  transition: all 0.3s ease-in-out;
  margin: 0.2rem;

  @media only screen and (max-width: 768px) {
    padding: 0px;
    font-size: 14px;
    height: 45px;
  }
`
