import React, { useState } from 'react'
import Header from '../../components/Header'

import { useNavigate } from 'react-router-dom'

import {
  Background,
  Container,
  ContainerButtons,
  FormInline,
  Label,
} from './styles'

import BackgroundImage from '../../assets/background-grey.png'
import { Modal } from '../../components/Modal'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'

const RecoverPassword = (props: any) => {
  let navigate = useNavigate()
  const [email, setEmail] = useState<any>('')

  React.useEffect(() => {
    document.title = props.title
  })

  return (
    <Background bg={BackgroundImage}>
      <Header />

      <Modal title="Recuperar Senha">
        <Container>
          <FormInline>
            <Label width={50}>
              <Input
                type="email"
                name="email"
                value={email}
                placeholder="E-mail"
                onChange={(input: any) => setEmail(input.target.value)}
                style={{ marginLeft: 15 }}
              />
            </Label>
          </FormInline>
        </Container>

        <ContainerButtons>
          <Button
            onClick={() => window.alert('Em desenvolvimento!')}
            borderRadius={12}
            color="#E9B219"
            borderColor="#E9B219"
          >
            Solicitar Senha
          </Button>

          <Button
            borderRadius={12}
            color="#F0F0F0"
            borderColor="#E9B219"
            textColor="#E9B219"
            onClick={() => navigate('/login')}
          >
            Retornar
          </Button>
        </ContainerButtons>
      </Modal>
    </Background>
  )
}

export default RecoverPassword
