import React, { useState } from 'react'

import {
  Background,
  Form,
  Link,
  TextArea,
  HeaderTitle,
  HeaderModal,
  LabelCheckbox,
  ButtonContainer,
  ContainerBottom,
} from './styles'

import Checkbox from '@mui/material/Checkbox'
import Header from '../../components/Header'
import BackgroundImage from '../../assets/background-grey.png'
import { Input } from '../../components/Input'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import theme from '../../styles/theme'
import Select from 'react-select'
import { Button } from '../../components/Button'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'

const SubscriptionTournament = (props: any) => {
  const [quartet, setQuartet] = useState<any>('')
  const [captain, setCaptain] = useState<any>('')
  const [emailCaptain, setEmailCaptain] = useState<any>('')
  const [athleteOne, setAthleteOne] = useState<any>('')
  const [emailAthtleteOne, setEmailAthtleteOne] = useState<any>('')
  const [athleteTwo, setAthleteTwo] = useState<any>('')
  const [emailAthtleteTwo, setEmailAthtleteTwo] = useState<any>('')
  const [athleteThree, setAthleteThree] = useState<any>('')
  const [emailAthtleteThree, setEmailAthtleteThree] = useState<any>('')
  const [athleteFour, setAthleteFour] = useState<any>('')
  const [emailAthtleteFour, setEmailAthtleteFour] = useState<any>('')
  const [music, setMusic] = useState<any>('')
  const [category, setCategory] = useState<any>([])
  const [about, setAbout] = useState<any>([])
  const [isTermsChecked, setIsTermsChecked] = useState<any>(false)
  const [isTermsUseImage, setIsTermsUseImage] = useState<any>(false)

  let navigate = useNavigate()

  const options = [
    { value: 'open', label: 'Open' },
    { value: 'iniciante', label: 'Iniciante' },
  ]

  const onSubmit = (e: any) => {
    e.preventDefault()
  }

  const handleUserInfo = {
    team: {
      name: quartet,
      category: category,
      video: null,
      music: music,
      about: about,
    },
    championship_id: 'c7f7bf4b-f844-4b25-920b-f421b5513141',
    players: [
      {
        name: captain,
        email: emailCaptain,
        instagram_account: null,
        is_captain: true,
        phone_number: null,
      },
      {
        name: athleteOne,
        email: emailAthtleteOne,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteTwo,
        email: emailAthtleteTwo,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteThree,
        email: emailAthtleteThree,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteFour,
        email: emailAthtleteFour,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
    ],
  }

  const handleSendChampionship = () => {
    api
      .post('/teams/create', JSON.parse(JSON.stringify(handleUserInfo)))
      .then((response) => {
        navigate('/tournament-confirm')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSubmit = () => {
    if (!isTermsChecked || !isTermsUseImage) {
      window.alert(
        'É necessário concordar com os termos do campeonato e permissão do uso de imagem!'
      )
    } else if (quartet === '') {
      window.alert('Favor informar o nome do quarteto!')
    } else if (captain === '' || emailCaptain === '') {
      window.alert('Favor informar o nome e e-mail do capita!')
    } else if (category.length === 0) {
      window.alert('Favor informar a categoria!')
    } else if (athleteOne === '' || emailAthtleteOne === '') {
      window.alert('Favor informar o nome e e-mail do primeiro atleta!')
    } else if (athleteTwo === '' || emailAthtleteTwo === '') {
      window.alert('Favor informar o nome e e-mail do segundo atleta!')
    } else if (athleteThree === '' || emailAthtleteThree === '') {
      window.alert('Favor informar o nome e e-mail do terceiro atleta!')
    } else if (athleteFour === '' || emailAthtleteFour === '') {
      window.alert('Favor informar o nome e e-mail do quarto atleta!')
    } else if (category === '') {
      window.alert('Favor informar a categoria!')
    } else {
      handleSendChampionship()
    }

    return false
  }

  React.useEffect(() => {
    document.title = props.title
  })

  return (
    <React.Fragment>
      <Background backgroundImage={BackgroundImage}>
        <Header />

        <div
          style={{
            backgroundColor: 'white',
            background: theme.colors.grey_01,
            borderRadius: 42,
            width: '90%',
            margin: 'auto',
            marginTop: 20,
            padding: 0,
          }}
        >
          <HeaderModal>
            <HeaderTitle>Inscrição Campeonato</HeaderTitle>
          </HeaderModal>
          <Form onSubmit={onSubmit} style={{ padding: 20 }}>
            <Input
              type="text"
              name="quartet"
              placeholder="Quarteto"
              value={quartet}
              onChange={(input: any) => setQuartet(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="captain"
              placeholder="Capita"
              value={captain}
              onChange={(input: any) => setCaptain(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="emailCaptain"
              placeholder="E-mail"
              value={emailCaptain}
              onChange={(input: any) => setEmailCaptain(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="athleteOne"
              placeholder="Atleta 1"
              value={athleteOne}
              onChange={(input: any) => setAthleteOne(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="emailAthleteOne"
              placeholder="E-mail"
              value={emailAthtleteOne}
              onChange={(input: any) => setEmailAthtleteOne(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="athleteTwo"
              placeholder="Atleta 2"
              value={athleteTwo}
              onChange={(input: any) => setAthleteTwo(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="emailAthleteTwo"
              placeholder="E-mail"
              value={emailAthtleteTwo}
              onChange={(input: any) => setEmailAthtleteTwo(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="athleteThree"
              placeholder="Atleta 3"
              value={athleteThree}
              onChange={(input: any) => setAthleteThree(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="emailAthleteThree"
              placeholder="E-mail"
              value={emailAthtleteThree}
              onChange={(input: any) =>
                setEmailAthtleteThree(input.target.value)
              }
            />
            <Input
              type="text"
              marginTop={10}
              name="athleteFour"
              value={athleteFour}
              placeholder="Atleta 4"
              onChange={(input: any) => setAthleteFour(input.target.value)}
            />
            <Input
              type="text"
              marginTop={10}
              name="emailAthleteFour"
              placeholder="E-mail"
              value={emailAthtleteFour}
              onChange={(input: any) =>
                setEmailAthtleteFour(input.target.value)
              }
            />
            <div style={{ margin: '0 auto', marginTop: 10 }}>
              <p style={{ color: '#000', textAlign: 'center', fontSize: 16 }}>
                Categoria
              </p>
              <Select
                placeholder="Selecione a categoria"
                isSearchable={false}
                defaultValue={category}
                onChange={(input) => setCategory(input.value)}
                options={options}
              />
            </div>

            <div style={{ margin: '0 auto', marginTop: 10 }}>
              <p style={{ color: '#000', textAlign: 'center', fontSize: 16 }}>
                Sobre vocês
              </p>
              <TextArea
                name="about"
                value={about}
                onChange={(input: any) => setAbout(input.target.value)}
              />
            </div>

            <div style={{ margin: '0 auto', marginTop: 10 }}>
              <p style={{ color: '#000', textAlign: 'center', fontSize: 16 }}>
                Músicas (máx: 4)
              </p>
              <TextArea
                name="music"
                value={music}
                onChange={(input: any) => setMusic(input.target.value)}
              />
            </div>

            <ContainerBottom>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue={isTermsUseImage}
                      value={isTermsUseImage}
                      onChange={(value) =>
                        setIsTermsUseImage(value.target.checked)
                      }
                    />
                  }
                  label={
                    <LabelCheckbox>
                      Permissão do{' '}
                      <Link
                        href="https://drive.google.com/file/d/11nuiQwnGV3DEpCbUgWcqQGkrNJvTu9BT/view"
                        target="_blank"
                      >
                        uso de imagem
                      </Link>
                    </LabelCheckbox>
                  }
                  style={{ color: '#000', paddingLeft: 30 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue={isTermsChecked}
                      value={isTermsChecked}
                      onChange={(value) =>
                        setIsTermsChecked(value.target.checked)
                      }
                    />
                  }
                  label={
                    <LabelCheckbox>
                      Concordo com os termos do campeonato
                    </LabelCheckbox>
                  }
                  style={{ color: '#000', paddingLeft: 30 }}
                />
              </FormGroup>

              <ButtonContainer>
                <Button
                  onClick={() => {
                    handleSubmit()
                  }}
                  borderRadius={12}
                  color="#E9B219"
                  borderColor="#E9B219"
                  padding={15}
                  type="submit"
                >
                  Seguir para o pagamento
                </Button>
              </ButtonContainer>
            </ContainerBottom>
          </Form>
        </div>
      </Background>
    </React.Fragment>
  )
}

export default SubscriptionTournament
