import React, { useState } from 'react'
import Header from '../../components/Header'

import { useNavigate } from 'react-router-dom'

import {
  Background,
  Container,
  ContainerButtons,
  ForgotTitle,
  FormInline,
  Label,
} from './styles'

import BackgroundImage from '../../assets/background-grey.png'
import { Modal } from '../../components/Modal'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import api from '../../services/api'

const Login = (props: any) => {
  let navigate = useNavigate()
  const [email, setEmail] = useState<any>('')
  const [password, setPassword] = useState<any>('')

  React.useEffect(() => {
    document.title = props.title
  })

  const loginInfo = {
    email,
    password,
  }

  const handleLoginUser = () => {
    api
      .post('/sessions', loginInfo)
      .then((response) => {
        sessionStorage.setItem('@token', response.data.token)
        window.alert('Usuário autenticado!')
        setEmail('')
        setPassword('')
        navigate('/')
      })
      .catch((error) => {
        window.alert('E-mail ou senha incorretos!')
        console.log(error)
      })
  }

  return (
    <Background bg={BackgroundImage}>
      <Header />

      <Modal title="Login">
        <Container>
          <FormInline>
            <Label width={50}>
              <Input
                type="email"
                name="email"
                value={email}
                placeholder="E-mail"
                onChange={(input: any) => setEmail(input.target.value)}
                style={{ marginLeft: 15 }}
              />
            </Label>
          </FormInline>
          <FormInline>
            <Label width={50}>
              <Input
                type="password"
                name="password"
                value={password}
                placeholder="Senha"
                onChange={(input: any) => setPassword(input.target.value)}
                style={{ marginLeft: 15 }}
              />
            </Label>
          </FormInline>
          <FormInline>
            <ForgotTitle href="/recover-password">Esqueci a senha</ForgotTitle>
          </FormInline>
        </Container>

        <ContainerButtons>
          <Button
            onClick={() => {
              handleLoginUser()
            }}
            borderRadius={12}
            color="#E9B219"
            borderColor="#E9B219"
          >
            Entrar
          </Button>

          <Button
            borderRadius={12}
            color="#F0F0F0"
            borderColor="#E9B219"
            textColor="#E9B219"
            onClick={() => navigate('/registration')}
          >
            Cadastrar
          </Button>
        </ContainerButtons>
      </Modal>
    </Background>
  )
}

export default Login
