import React from 'react'
import {
  Container,
  ContainerCentered,
  ContainerModal,
  HeaderModal,
  HeaderTitle,
  Logo,
  TournamentImage,
} from './styles'

import LogoAltinha from '../../assets/logo.svg'
import LogoTournament from '../../assets/logo-tournament.svg'

export const Modal = ({
  title,
  width,
  isDark,
  isTournament,
  children,
}: any) => (
  <Container>
    <ContainerCentered>
      <ContainerModal width={width}>
        <HeaderModal isDark={isDark}>
          <HeaderTitle>{title}</HeaderTitle>
          <Logo src={LogoAltinha} alt="Logo Altinha" />
          {isTournament && (
            <TournamentImage
              src={LogoTournament}
              alt="Logo Campeonato Altinha"
            />
          )}
        </HeaderModal>
        {children}
      </ContainerModal>
    </ContainerCentered>
  </Container>
)
