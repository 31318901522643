import React, { useEffect, useMemo, useState } from 'react'

import { Background } from './styles'

import Header from '../../../components/Header'
import BackgroundImage from '../../../assets/background-grey.png'

import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import api from '../../../services/api'
import Table from '../../../components/Table'

const Genres = ({ values }: any) => {
  return (
    <>
      {values.map((genre: any, idx: any) => {
        return (
          <span key={idx} className="badge">
            {genre}
          </span>
        )
      })}
    </>
  )
}

const Teams = (props: any) => {
  React.useEffect(() => {
    document.title = props.title
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Age',
            accessor: 'age',
          },
          {
            Header: 'Visits',
            accessor: 'visits',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Profile Progress',
            accessor: 'progress',
          },
        ],
      },
    ],
    []
  )

  let navigate = useNavigate()

  const [info, setInfo] = useState<any>([])

  useEffect(() => {
    api
      .get<any>('/teams/all')
      .then((resposta) => setInfo(resposta.data))
      .catch((erro) => console.log(erro))
  }, [])

  const numbers = [1, 2, 3, 4, 5]
  const dobro = numbers.map((numbers) => numbers * 2)

  return (
    <React.Fragment>
      <Background backgroundImage={BackgroundImage}>
        <Header eventsLink="https://emaltabrasil.com.br#events" />

        {/* {info.map((item: any) => (
          <div key={item[0].teams[0].id}>
            <ul>
              <li>{info[0].teams[0].id}</li>
            </ul>
          </div>
        ))} */}

        {/* <ul>
          {numbers.map((number) => (
            <li>{number}</li>
          ))}
        </ul> */}

        {/* {post.map((item: any) => (
          <div>{item} </div>
        ))} */}

        {/* <ul>
          {info.map((data: any) => (
            <li>{data.teams[0].id}</li>
          ))}
        </ul> */}

        <h1>teste</h1>

        {/* <Table columns={columns} data={data} /> */}
      </Background>
    </React.Fragment>
  )
}

export default Teams
