import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const HeaderTitle = styled.h1`
  font-size: 26px;
  text-transform: uppercase;
  font-family: 'Archivo';
  text-align: center;
  margin: 0px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`

export const Link = styled.a`
  text-decoration: underline;
  color: #e9b219;
  font-size: 1rem;
  margin: 1rem;

  &:hover,
  &:active,
  &:focus {
    color: blue;
    text-decoration: underline;
  }
`

export const HeaderModal = styled.div<any>`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
  background: linear-gradient(
    90deg,
    #f7a72a 6.6%,
    #e96c19 52.57%,
    #e9b426 94.5%
  );

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 65px;
  }
`
