import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import api from '../../services/api'

import { Row, Background, Span, FormInline, Label, Link } from './styles'

import BackgroundImage from '../../assets/background-grey.png'
import { Modal } from '../../components/Modal'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { InputMask } from '../../components/InputMask'
import RegistrationMobile from '../RegistrationMobile'

const Registration = (props: any) => {
  const [email, setEmail] = useState<any>('')
  const [name, setName] = useState<any>('')
  const [username, setUsername] = useState<any>('')
  const [password, setPassword] = useState<any>('')
  const [confirmPassword, setConfirmPassword] = useState<any>('')
  const [whatsApp, setWhatsApp] = useState<any>('')
  const [error, setError] = useState<any>(null)

  React.useEffect(() => {
    document.title = props.title
  })

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 769

  const userInfo = {
    name,
    email,
    password,
  }

  const registerUser = () => {
    api
      .post('/users', userInfo)
      .then((response) => {
        window.alert('Usuário cadastrado com sucesso!')
        setName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setWhatsApp('')
      })
      .catch((error) => {
        console.log(error)
        window.alert('Algo deu errado!')
      })
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChange = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid')
    } else {
      setError(null)
    }

    setEmail(event.target.value)
  }

  return (
    <React.Fragment>
      {!isMobile ? (
        <Background backgroundImage={BackgroundImage}>
          <Header />

          <Modal title="Cadastro">
            <div style={{ margin: '20px 40px' }}>
              <FormInline>
                <Label width={100}>
                  <Span>Nome Completo *</Span>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(input: any) => setName(input.target.value)}
                  />
                </Label>
              </FormInline>
              <FormInline>
                <Label>
                  <Span width={280}>Nome de Usuário *</Span>
                  <Input
                    type="text"
                    name="username"
                    value={username}
                    onChange={(input: any) => setUsername(input.target.value)}
                  />
                </Label>
                <Label>
                  <Span width={80}>E-mail *</Span>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </Label>
              </FormInline>
              <FormInline>
                <Label>
                  <Span width={80}>Senha *</Span>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(input: any) => setPassword(input.target.value)}
                  />
                </Label>
                <Label>
                  <Span width={220}>Repetir senha *</Span>
                  <Input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(input: any) =>
                      setConfirmPassword(input.target.value)
                    }
                  />
                </Label>
              </FormInline>
              <FormInline>
                <Label width={140}>
                  <Span width={120}>WhatsApp *</Span>
                  <InputMask
                    mask="(99) 99999-9999"
                    type="text"
                    name="whatsApp"
                    value={whatsApp}
                    onChange={setWhatsApp}
                  />
                </Label>
              </FormInline>
            </div>

            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0rem 4.5rem 2.5rem',
              }}
            >
              <Link href={'/login'}>Já tenho cadastro</Link>
              <Button
                onClick={() => {
                  registerUser()
                }}
                borderRadius={12}
                color="#E9B219"
                borderColor="#E9B219"
              >
                Cadastrar
              </Button>
            </Row>
          </Modal>
        </Background>
      ) : (
        <RegistrationMobile />
      )}
    </React.Fragment>
  )
}

export default Registration
