import {
  Column,
  Container,
  Button,
  Title,
  ChampionshipInformation,
  LocationTitle,
  BackgroundTournament,
} from './styles'
import ChampionshipImage from '../../assets/championships/third-edition.jpeg'
import theme from '../../styles/theme'

export const CardChampionship = ({
  title,
  onClick,
  location,
  ...props
}: any) => {
  return (
    <Container {...props}>
      <BackgroundTournament src={ChampionshipImage} alt="" />
      <Title>{title}</Title>
      <ChampionshipInformation>
        <Column>
          <LocationTitle>{location}</LocationTitle>
          <LocationTitle fontSize={13} color={theme.colors.yellow_01}>
            Categorias open e iniciante
          </LocationTitle>
          <Button onClick={onClick}>Inscreva-se</Button>
        </Column>
      </ChampionshipInformation>
    </Container>
  )
}
