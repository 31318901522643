const theme = {
  colors: {
    orange_01: '#e96c19',
    white: '#ffffff',
    accent_blue: '#90b3c2',
    yellow_01: '#e9b219',
    black: '#000000',
    grey_01: '#F0F0F0',
    grey_02: '#DBDBDB',
  },
  fonts: ['Inter', 'Roboto'],
}

export default theme
