import styled from 'styled-components'
import theme from '../../styles/theme'

export const Container = styled.input<any>`
  vertical-align: middle;
  margin: 0px 10px;
  padding: 10px 20px;
  height: 35px;
  background-color: ${(p) => p.backgroundColor || theme.colors.grey_02};
  border-radius: ${(p) => p.borderRadius || 16}px;
  border: 1px solid #ddd;
  margin-top: ${(p) => p.marginTop || 0}px;

  @media only screen and (max-width: 768px) {
    margin: 5px 0px;
  }
`
