import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${(p) => (p.height ? p.height : '100%')}px;
  min-height: 100%;
  position: relative;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Information = styled.div<any>`
  width: 80%;
  height: auto;
  padding: ${(p) => (p.padding ? p.padding : '6rem 0 4rem')};
`

export const InformationDescription = styled.p`
  width: 60%;
  font-size: 18px;
  text-align: justify;
  font-weight: 300;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`

export const InformationTitle = styled.h1`
  font-size: 45px;

  @media only screen and (max-width: 768px) {
    text-align: center;
    padding-bottom: 0rem;
  }
`

export const ContainerCalculator = styled.div`
  width: 80%;
  height: auto;
  padding: 1rem 0;
`

export const FooterTitle = styled.p`
  text-align: center;
  padding-bottom: 1.5rem;
  font-size: 12px;
`
