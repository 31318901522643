import styled from 'styled-components'
import theme from '../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  border-radius: 35px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 768px) {
    margin-right: 0rem;
  }
`

export const Title = styled.h1`
  color: ${theme.colors.orange_01};
  padding-top: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 22px;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

export const LocationTitle = styled.h2<any>`
  color: ${(p) => (p.color ? p.color : theme.colors.orange_01)};
  text-align: center;
  font-weight: 400;
  font-size: ${(p) => (p.fontSize ? p.fontSize : 14)}px;
  margin-top: 5px;

  @media only screen and (max-width: 768px) {
    font-size: ${(p) => (p.fontSize ? p.fontSize : 18)}px;
  }
`

export const BackgroundTournament = styled.img`
  width: 100%;
  height: 250px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;

  @media only screen and (max-width: 967px) {
    flex-direction: column;
  }
`

export const ChampionshipInformation = styled.div`
  display: flex;
  padding: 0.5rem 2rem;
  justify-content: space-evenly;
  align-items: center;
  height: 25%;

  @media only screen and (max-width: 967px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled.button`
  background-color: ${theme.colors.yellow_01};
  border: 1px solid ${theme.colors.yellow_01};
  border-radius: 25px;
  color: ${theme.colors.white};
  margin-right: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    width: 90%;
    height: 35px;
  }
`
