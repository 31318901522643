import React, { useState } from 'react'
import Header from '../../components/Header'

import { useNavigate } from 'react-router-dom'

import {
  Background,
  Container,
  ContainerButtons,
  Icon,
  FormInline,
  Span,
  Text,
  Label,
} from './styles'

import BackgroundImage from '../../assets/background-orange.png'
import WhatsAppImage from '../../assets/whatsapp-logo.png'
import { Modal } from '../../components/Modal'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import api from '../../services/api'

const ScoreResult = (props: any) => {
  let navigate = useNavigate()
  const [email, setEmail] = useState<any>('')
  const [password, setPassword] = useState<any>('')

  const loginInfo = {
    email,
    password,
  }

  React.useEffect(() => {
    document.title = props.title
  })

  const handleLoginUser = () => {
    api
      .post('/sessions', loginInfo)
      .then((response) => {
        sessionStorage.setItem('@token', response.data.token)
        window.alert('Usuário autenticado!')
        setEmail('')
        setPassword('')
        navigate('/')
      })
      .catch((error) => {
        window.alert('E-mail ou senha incorretos!')
        console.log(error)
      })
  }

  return (
    <Background bg={BackgroundImage}>
      <Header />

      <Modal width={700} title="Resultado Bola no Ar" isDark>
        <Container>
          <FormInline>
            <Text>Quer receber os números dessa alta?</Text>
            <Label width={100}>
              <Span>Quarteto</Span>
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="Informe o quarteto"
                onChange={(input: any) => setEmail(input.target.value)}
              />
            </Label>
          </FormInline>
          <FormInline>
            <Text>Quer receber os números dessa alta?</Text>
            <Label width={100}>
              <Span width={160}>Atleta 1</Span>
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="Nome"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="WhatsApp"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <Icon src={WhatsAppImage} />
            </Label>

            <Label width={100}>
              <Span width={160}>Atleta 2</Span>
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="Nome"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="WhatsApp"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <Icon src={WhatsAppImage} />
            </Label>

            <Label width={100}>
              <Span width={160}>Atleta 3</Span>
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="Nome"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <Input
                type="text"
                name="quartet"
                value={email}
                placeholder="WhatsApp"
                onChange={(input: any) => setEmail(input.target.value)}
              />
              <a href="#">
                <Icon src={WhatsAppImage} />
              </a>
            </Label>
          </FormInline>
        </Container>

        <ContainerButtons>
          <Button
            onClick={() => {
              window.alert('Em desenvolvimento!')
            }}
            borderRadius={12}
            color="#E9B219"
            borderColor="#E9B219"
          >
            Enviar
          </Button>
        </ContainerButtons>
      </Modal>
    </Background>
  )
}

export default ScoreResult
