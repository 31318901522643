import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  overflow: auto;
  overflow-y: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LabelCheckbox = styled.span`
  color: #000;
  font-size: 14px;
  margin: 0;
`

export const ContainerBottom = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem;
  }
`

export const Link = styled.a`
  text-decoration: underline;
  color: blue;

  &:hover,
  &:active,
  &:focus {
    color: blue;
    text-decoration: underline;
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem;
  }
`

export const TextArea = styled.textarea<any>`
  min-height: 8vh;
  height: unset;
  background-color: #d6d6d6;
  margin: 10px;
  width: ${(p) => p.width};

  @media only screen and (max-width: 768px) {
    min-height: 2vh;
  }
`

export const ContainerForm = styled.div`
  width: 50%;
  height: 300px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`

export const FormInline = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const Span = styled.span<any>`
  width: ${(p) => p.width || 100}px;
`

export const Label = styled.label<any>`
  align-items: center;
  display: flex;
  color: black;
  width: ${(p) => (p.width ? p.width : 50)}%;
  margin-top: ${(p) => p.marginTop || 0}px;
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
