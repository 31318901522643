import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'
import theme from '../../styles/theme'

export const HeaderSection = styled('header')`
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`

export const LogoContainer = styled(Link)`
  display: flex;
`

export const NavLink = styled('div')`
  display: inline-block;
  text-align: center;
`

export const CustomNavLink = styled('div')`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`

export const ContactWrapper = styled('div')<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? '100%' : '110px')};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`

export const Burger = styled('div')`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: ${theme.colors.black};
  }
`

export const NotHidden = styled('div')`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`

export const Container = styled('div')<any>`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 80px;
  border-top: ${(p) => (p.border ? '1px solid #CDD1D4' : '')};
  background: linear-gradient(180deg, #e7e7e7 0%, rgba(110, 110, 110, 0) 135%);
  display: ${(p) => p.display || 'auto'};

  @media only screen and (max-width: 1024px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 18px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0 18px;
  }
`

export const ContainerMenu = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 764px) {
    display: grid;
  }
`

export const Menu = styled('h5')`
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1rem;
  font-weight: 400;
  transition: color 0.2s ease-in;
  color: #fff;
  margin: 0.5rem 1.5rem;

  @media only screen and (max-width: 764px) {
    text-align: left;
    color: #000 !important;
  }
`

export const Label = styled('span')`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`

export const Span = styled('span')`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    text-underline-position: under;
    text-decoration: underline;
  }
`

export const LinkNav = styled.a`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    text-underline-position: under;
    text-decoration: underline;
  }

  @media only screen and (max-width: 764px) {
    color: #000;
  }
`
