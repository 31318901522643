import axios from 'axios'

const http = axios.create({
  baseURL: 'https://api.bolanoar.app',
  headers: {
    'Content-Type': 'application/json',
  },
})

export default http
