import { ContainerButton } from './styles'

export const Button = ({
  color,
  textColor,
  borderColor,
  borderRadius,
  children,
  onClick,
  padding,
  width,
}: any) => (
  <ContainerButton
    color={color}
    borderColor={borderColor}
    textColor={textColor}
    onClick={onClick}
    borderRadius={borderRadius}
    width={width}
    padding={padding}
  >
    {children}
  </ContainerButton>
)
