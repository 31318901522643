import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const Container = styled.div`
  padding-top: 20px;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

export const FormInline = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const Label = styled.label<any>`
  align-items: center;
  display: flex;
  color: black;
  width: ${(p) => (p.width ? p.width : 50)}%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 15px;
  }
`
