import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Row,
  Background,
  Container,
  ContainerCalculator,
  Information,
  InformationDescription,
  InformationTitle,
  FooterTitle,
} from './styles'

import Header from '../../components/Header'
import { Calculator } from '../../components/Calculator'
import BackgroundImage from '../../assets/background-screenshot.png'
import FooterBackground from '../../assets/footer-background.png'
import { CardChampionship } from '../../components/CardChampionship'
import { Button } from '../../components/Button'

const Home = (props: any) => {
  const token = sessionStorage.getItem('@token')
  let navigate = useNavigate()

  useEffect(() => {
    document.title = props.title
  })

  return (
    <React.Fragment>
      <Background backgroundImage={BackgroundImage}>
        <Header eventsLink="#events" />

        <Container>
          <Information>
            <InformationTitle>Treine, jogue e pontue.</InformationTitle>
            <InformationDescription>
              Já se imaginou vivendo um estilo de vida ligado à praia, natureza
              e esportes?
              <br />
              <br />
              Há alguns anos o nosso maior desafio, que é crescer e
              profissionalizar a Altinha, vem se transformando em realidade.
              Através de campeonatos, eventos e parcerias, estamos conseguindo
              proporcionar a evolução técnica, física e mental de atletas pelo
              mundo inteiro.
              <br />
              <br />
              Aquilo que era uma brincadeira já atravessou fronteiras e criou
              uma dinâmica própria de aprendizado.
              <br />
              <br />O Em Alta busca o seu melhor jogo! O mais solto, o mais
              intenso, o mais conectado com você mesmo e com o nosso estilo de
              viver a vida: sempre com a Bola no Ar.
            </InformationDescription>
          </Information>
        </Container>

        {/* <Container>
          <ContainerCalculator>
            <Calculator />
          </ContainerCalculator>
        </Container> */}

        <Container>
          <Information>
            <InformationTitle>Bora para o próximo nível?</InformationTitle>
            <InformationDescription>
              O que você acharia se tivesse a oportunidade de treinar nos
              padrões dos campeonatos? Este é o primeiro passo para desenvolver
              ainda mais o nosso esporte. Treine, jogue e pontue.
            </InformationDescription>
            <InformationDescription>
              Queremos que você faça parte. Fique ligado nas nossas redes
              sociais e aguarde!
            </InformationDescription>
            <InformationDescription>
              O outro nível está chegando!
            </InformationDescription>
            <Button
              width="250px"
              borderRadius={8}
              onClick={() => navigate('/registration')}
            >
              Cadastre-se e faça parte
            </Button>
          </Information>
        </Container>
      </Background>

      <Background backgroundImage={FooterBackground}>
        <Container>
          <Information padding="3rem 0 3rem">
            <InformationTitle id="events">
              Próximos Campeonatos
            </InformationTitle>
            <Row>
              <CardChampionship
                onClick={() =>
                  navigate(
                    '/tournament-registration/c7f7bf4b-f844-4b25-920b-f421b5513141'
                  )
                }
                location="Praia do Madeiro - Pipa/RN"
                title="10/12 e 11/12"
              />
            </Row>
          </Information>
        </Container>

        <FooterTitle>© 2022 Em Alta Brasil - Desde 2015</FooterTitle>
      </Background>
    </React.Fragment>
  )
}

export default Home
