import React, { useState } from 'react'

import { Background, Form, Link, Row, HeaderTitle, HeaderModal } from './styles'

import Header from '../../components/Header'
import BackgroundImage from '../../assets/background-grey.png'
import { Input } from '../../components/Input'
import theme from '../../styles/theme'
import { Button } from '../../components/Button'
import api from '../../services/api'

const RegistrationMobile = (props: any) => {
  const [email, setEmail] = useState<any>('')
  const [name, setName] = useState<any>('')
  const [username, setUsername] = useState<any>('')
  const [password, setPassword] = useState<any>('')
  const [confirmPassword, setConfirmPassword] = useState<any>('')
  const [whatsApp, setWhatsApp] = useState<any>('')
  const [error, setError] = useState<any>(null)

  const userInfo = {
    name,
    email,
    password,
  }

  const registerUser = () => {
    api
      .post('/users', userInfo)
      .then((response) => {
        window.alert('Usuário cadastrado com sucesso!')
        setName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setWhatsApp('')
      })
      .catch((error) => {
        console.log(error)
        window.alert('Algo deu errado!')
      })
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChange = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid')
    } else {
      setError(null)
    }

    setEmail(event.target.value)
  }

  React.useEffect(() => {
    document.title = props.title
  })

  return (
    <React.Fragment>
      <Background backgroundImage={BackgroundImage}>
        <Header />

        <div
          style={{
            backgroundColor: 'white',
            background: theme.colors.grey_01,
            borderRadius: 42,
            width: '90%',
            margin: 'auto',
            marginTop: 20,
            padding: 0,
          }}
        >
          <HeaderModal>
            <HeaderTitle>Cadastro</HeaderTitle>
          </HeaderModal>
          <Form style={{ padding: 20 }}>
            <Input
              type="text"
              name="name"
              placeholder="Nome completo"
              value={name}
              onChange={(input: any) => setName(input.target.value)}
            />
            <Input
              type="text"
              name="username"
              placeholder="Nome de usuário"
              value={username}
              onChange={(input: any) => setUsername(input.target.value)}
            />
            <Input
              type="email"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={handleChange}
            />
            <Input
              type="whatsApp"
              name="whatsApp"
              placeholder="WhatsApp"
              value={whatsApp}
              onChange={(input: any) => setWhatsApp(input.target.value)}
            />
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              value={password}
              onChange={(input: any) => setPassword(input.target.value)}
            />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirmar senha"
              value={confirmPassword}
              onChange={(input: any) => setConfirmPassword(input.target.value)}
            />
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Link href={'/login'}>Já tenho cadastro</Link>
              <Button
                onClick={() => {
                  registerUser()
                }}
                borderRadius={12}
                color="#E9B219"
                borderColor="#E9B219"
              >
                Cadastrar
              </Button>
            </Row>
          </Form>
        </div>
      </Background>
    </React.Fragment>
  )
}

export default RegistrationMobile
