import React, { useState, useEffect } from 'react'

import {
  ContainerBottom,
  ButtonContainer,
  Background,
  ContainerModal,
  ContainerForm,
  LabelCheckbox,
  FormInline,
  Label,
  Span,
  Link,
  TextArea,
} from './styles'

import Select from 'react-select'
import Header from '../../components/Header'
import BackgroundImage from '../../assets/background-grey.png'
import { Modal } from '../../components/Modal'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import SubscriptionTournament from '../SubscriptionTournament'

const NewTournament = (props: any) => {
  const [quartet, setQuartet] = useState<any>('')
  const [captain, setCaptain] = useState<any>('')
  const [emailCaptain, setEmailCaptain] = useState<any>('')
  const [athleteOne, setAthleteOne] = useState<any>('')
  const [emailAthtleteOne, setEmailAthtleteOne] = useState<any>('')
  const [athleteTwo, setAthleteTwo] = useState<any>('')
  const [emailAthtleteTwo, setEmailAthtleteTwo] = useState<any>('')
  const [athleteThree, setAthleteThree] = useState<any>('')
  const [emailAthtleteThree, setEmailAthtleteThree] = useState<any>('')
  const [athleteFour, setAthleteFour] = useState<any>('')
  const [emailAthtleteFour, setEmailAthtleteFour] = useState<any>('')
  const [music, setMusic] = useState<any>('')
  const [category, setCategory] = useState<any>([])
  const [about, setAbout] = useState<any>([])
  const [isTermsChecked, setIsTermsChecked] = useState<any>(false)
  const [isTermsUseImage, setIsTermsUseImage] = useState<any>(false)

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 967

  React.useEffect(() => {
    document.title = props.title
  })

  const handleUserInfo = {
    team: {
      name: quartet,
      category: category,
      video: null,
      music: music,
      about: about,
    },
    championship_id: 'c7f7bf4b-f844-4b25-920b-f421b5513141',
    players: [
      {
        name: captain,
        email: emailCaptain,
        instagram_account: null,
        is_captain: true,
        phone_number: null,
      },
      {
        name: athleteOne,
        email: emailAthtleteOne,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteTwo,
        email: emailAthtleteTwo,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteThree,
        email: emailAthtleteThree,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
      {
        name: athleteFour,
        email: emailAthtleteFour,
        instagram_account: null,
        is_captain: false,
        phone_number: null,
      },
    ],
  }

  let navigate = useNavigate()

  const options = [
    { value: 'open', label: 'Open' },
    { value: 'iniciante', label: 'Iniciante' },
  ]

  const handleSendChampionship = () => {
    api
      .post('/teams/create', JSON.parse(JSON.stringify(handleUserInfo)))
      .then((response) => {
        navigate('/tournament-confirm')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSubmit = () => {
    if (!isTermsChecked || !isTermsUseImage) {
      window.alert(
        'É necessário concordar com os termos do campeonato e permissão do uso de imagem!'
      )
    } else if (quartet === '') {
      window.alert('Favor informar o nome do quarteto!')
    } else if (captain === '' || emailCaptain === '') {
      window.alert('Favor informar o nome e e-mail do capita!')
    } else if (category.length === 0) {
      window.alert('Favor informar a categoria!')
    } else if (athleteOne === '' || emailAthtleteOne === '') {
      window.alert('Favor informar o nome e e-mail do primeiro atleta!')
    } else if (athleteTwo === '' || emailAthtleteTwo === '') {
      window.alert('Favor informar o nome e e-mail do segundo atleta!')
    } else if (athleteThree === '' || emailAthtleteThree === '') {
      window.alert('Favor informar o nome e e-mail do terceiro atleta!')
    } else if (category === '') {
      window.alert('Favor informar a categoria!')
    } else {
      handleSendChampionship()
    }
  }

  return (
    <React.Fragment>
      {!isMobile ? (
        <Background backgroundImage={BackgroundImage}>
          <Header
            eventsLink="https://emaltabrasil.com.br#events"
            display={isMobile ? 'none' : 'auto'}
          />

          <Modal isTournament title="Inscrição Campeonato">
            <ContainerModal>
              <ContainerForm>
                <FormInline>
                  <Label width={100}>
                    <Input
                      type="text"
                      name="quartet"
                      placeholder="Quarteto"
                      value={quartet}
                      onChange={(input: any) => setQuartet(input.target.value)}
                    />
                  </Label>

                  <Label width={100}>
                    <Input
                      type="text"
                      marginTop={10}
                      name="captain"
                      placeholder="Capita"
                      value={captain}
                      onChange={(input: any) => setCaptain(input.target.value)}
                    />
                    <Input
                      type="text"
                      marginTop={10}
                      name="emailCaptain"
                      placeholder="E-mail"
                      value={emailCaptain}
                      onChange={(input: any) =>
                        setEmailCaptain(input.target.value)
                      }
                    />
                  </Label>

                  <Label width={100}>
                    <Input
                      type="text"
                      marginTop={10}
                      name="athleteOne"
                      placeholder="Atleta 1"
                      value={athleteOne}
                      onChange={(input: any) =>
                        setAthleteOne(input.target.value)
                      }
                    />
                    <Input
                      type="text"
                      marginTop={10}
                      name="emailAthleteOne"
                      placeholder="E-mail"
                      value={emailAthtleteOne}
                      onChange={(input: any) =>
                        setEmailAthtleteOne(input.target.value)
                      }
                    />
                  </Label>

                  <Label width={100}>
                    <Input
                      type="text"
                      marginTop={10}
                      name="athleteTwo"
                      placeholder="Atleta 2"
                      value={athleteTwo}
                      onChange={(input: any) =>
                        setAthleteTwo(input.target.value)
                      }
                    />
                    <Input
                      type="text"
                      marginTop={10}
                      name="emailAthleteTwo"
                      placeholder="E-mail"
                      value={emailAthtleteTwo}
                      onChange={(input: any) =>
                        setEmailAthtleteTwo(input.target.value)
                      }
                    />
                  </Label>

                  <Label width={100}>
                    <Input
                      type="text"
                      marginTop={10}
                      name="athleteThree"
                      placeholder="Atleta 3"
                      value={athleteThree}
                      onChange={(input: any) =>
                        setAthleteThree(input.target.value)
                      }
                    />
                    <Input
                      type="text"
                      marginTop={10}
                      name="emailAthleteThree"
                      placeholder="E-mail"
                      value={emailAthtleteThree}
                      onChange={(input: any) =>
                        setEmailAthtleteThree(input.target.value)
                      }
                    />
                  </Label>

                  <Label width={100}>
                    <Input
                      type="text"
                      marginTop={10}
                      name="athleteFour"
                      value={athleteFour}
                      placeholder="Atleta 4"
                      onChange={(input: any) =>
                        setAthleteFour(input.target.value)
                      }
                    />
                    <Input
                      type="text"
                      marginTop={10}
                      name="emailAthleteFour"
                      placeholder="E-mail"
                      value={emailAthtleteFour}
                      onChange={(input: any) =>
                        setEmailAthtleteFour(input.target.value)
                      }
                    />
                  </Label>
                </FormInline>
              </ContainerForm>

              <ContainerForm>
                <FormInline>
                  <Label width={100}>
                    <Span>Categoria</Span>
                    <Select
                      placeholder="Selecione a categoria"
                      isSearchable={false}
                      defaultValue={category}
                      onChange={(input) => setCategory(input.value)}
                      options={options}
                    />
                  </Label>

                  <Label marginTop={15} width={100}>
                    Sobre vocês
                    <TextArea
                      name="about"
                      value={about}
                      onChange={(input: any) => setAbout(input.target.value)}
                    />
                  </Label>

                  <Label marginTop={15} width={120}>
                    Músicas (máx: 4)
                    <TextArea
                      name="music"
                      value={music}
                      onChange={(input: any) => setMusic(input.target.value)}
                    />
                  </Label>
                </FormInline>
              </ContainerForm>
            </ContainerModal>

            <ContainerBottom>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue={isTermsUseImage}
                      value={isTermsUseImage}
                      onChange={(value) =>
                        setIsTermsUseImage(value.target.checked)
                      }
                    />
                  }
                  label={
                    <LabelCheckbox>
                      Permissão do{' '}
                      <Link
                        href="https://drive.google.com/file/d/11nuiQwnGV3DEpCbUgWcqQGkrNJvTu9BT/view"
                        target="_blank"
                      >
                        uso de imagem
                      </Link>
                    </LabelCheckbox>
                  }
                  style={{ color: '#000', paddingLeft: 30 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue={isTermsChecked}
                      value={isTermsChecked}
                      onChange={(value) =>
                        setIsTermsChecked(value.target.checked)
                      }
                    />
                  }
                  label={
                    <LabelCheckbox>
                      Concordo com os termos do campeonato
                    </LabelCheckbox>
                  }
                  style={{ color: '#000', paddingLeft: 30 }}
                />
              </FormGroup>

              <ButtonContainer>
                <Button
                  onClick={() => {
                    handleSubmit()
                  }}
                  width={0}
                  borderRadius={12}
                  color="#E9B219"
                  borderColor="#E9B219"
                >
                  Seguir para o pagamento
                </Button>
              </ButtonContainer>
            </ContainerBottom>
          </Modal>
        </Background>
      ) : (
        <SubscriptionTournament />
      )}
    </React.Fragment>
  )
}

export default NewTournament
