import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Link = styled.a`
  text-decoration: underline;
  color: #e9b219;
  font-size: 1rem;

  &:hover,
  &:active,
  &:focus {
    color: blue;
    text-decoration: underline;
  }
`

export const Span = styled.span<any>`
  width: ${(p) => p.width || 180}px;
  font-family: 'Inter';
  font-size: 16px;
`

export const FormInline = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const Label = styled.label<any>`
  align-items: center;
  display: flex;
  color: black;
  width: ${(p) => (p.width ? p.width : 50)}%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
