import styled from 'styled-components'
import theme from '../../styles/theme'
import InputMask from 'react-input-mask'

export const Input = styled(InputMask)`
  vertical-align: middle;
  margin: 0px 10px;
  padding: 10px;
  height: 35px;
  background-color: ${theme.colors.grey_02};
  border-radius: 16px;
  border: 1px solid #ddd;
  color: black;

  @media only screen and (max-width: 768px) {
    margin: 10px 0;
  }
`
