import { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Home from '../screens/Home'
import Login from '../screens/Login'
import ScoreResult from '../screens/ScoreResult'
import Registration from '../screens/Registration'
import NewTournament from '../screens/NewTournament'
import RecoverPassword from '../screens/RecoverPassword'
import TournamentConfirm from '../screens/TournamentConfirm'
import SubscriptionTournament from '../screens/SubscriptionTournament'
import Teams from '../screens/Admin/Teams'
import theme from '../styles/theme'
import { Styles } from '../styles/styles'

function AppRouter() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={null}>
        <Styles />
        <Router>
          <Routes>
            <Route path="/" element={<Home title="Em Alta | Home" />} />
            <Route
              path="/registration"
              element={<Registration title="Cadastro" />}
            />
            <Route
              path="/tournament-registration/:tournamentId"
              element={<NewTournament title="Inscrição" />}
            />
            <Route
              path="/recover-password"
              element={<RecoverPassword title="Recuperar Senha" />}
            />
            <Route
              path="/tournament-confirm"
              element={<TournamentConfirm title="Confirme sua inscrição" />}
            />
            <Route
              path="/subscription-tournament"
              element={<SubscriptionTournament title="Inscrição" />}
            />
            <Route
              path="/admin/show-teams"
              element={<Teams title="Administrador | Times" />}
            />
            <Route path="/result" element={<ScoreResult title="Resultado" />} />
            <Route path="/login" element={<Login title="Login" />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  )
}

export default AppRouter
