import styled from 'styled-components'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`
