import styled from 'styled-components'
import theme from '../../styles/theme'

export const Container = styled.div`
  width: 100vw;
  height: auto;
  top: 0;
`

export const ContainerCentered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ContainerModal = styled.div<any>`
  width: ${(p) => (p.width ? p.width : 900)}px;
  background: ${theme.colors.grey_01};
  color: white;
  /* z-index: 10; */
  border-radius: 42px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 768px) {
    justify-content: space-around;
    width: 400px;
  }
`

export const HeaderModal = styled.div<any>`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
  background: ${(p) =>
    p.isDark
      ? theme.colors.black
      : 'linear-gradient( 90deg, #f7a72a 6.6%, #e96c19 52.57%, #e9b426 94.5% )'};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 45px;
  }
`

export const HeaderTitle = styled.h1`
  font-size: 28px;
  text-transform: uppercase;
  font-family: 'Archivo';
  text-align: center;
  margin: 0px;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`

export const Logo = styled.img`
  position: absolute;
  right: 30px;
  width: 101px;
  height: 64px;

  @media only screen and (max-width: 768px) {
    position: relative;
    display: none;
    right: 0px;
  }
`

export const TournamentImage = styled.img`
  position: absolute;
  left: 30px;
  width: 101px;
  height: 64px;

  @media only screen and (max-width: 768px) {
    position: relative;
    display: none;
    right: 0px;
  }
`
