import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const Background = styled.div<any>`
  background-image: url(${(p) => p.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
  position: relative;
`

export const Container = styled.div`
  padding-top: 20px;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.5rem;
`

export const FormInline = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.5rem 1.5rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`

export const Text = styled.p`
  color: ${theme.colors.orange_01};
  font-size: 20px;
`

export const Span = styled.span<any>`
  width: ${(p) => p.width || 100}px;
`

export const Label = styled.label<any>`
  align-items: center;
  display: flex;
  color: black;
  width: ${(p) => (p.width ? p.width : 50)}%;
  margin: 5px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 15px;
  }
`
