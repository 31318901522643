import React from 'react'

import {
  Background,
  Wrapper,
  ContainerModal,
  Description,
  Title,
} from './styles'

import Header from '../../components/Header'
import BackgroundImage from '../../assets/background-grey.png'
import { Modal } from '../../components/Modal'

const TournamentConfirm = (props: any) => {
  React.useEffect(() => {
    document.title = props.title
  })

  return (
    <React.Fragment>
      <Background backgroundImage={BackgroundImage}>
        <Header />

        <Modal isTournament title="Confirme sua inscrição">
          <ContainerModal>
            <Wrapper>
              <Title>Falta pouco!</Title>
              <Description>
                Recebemos sua inscrição! Vamos analisar e entrar em contato para
                passar as informações para pagamento!
              </Description>
            </Wrapper>
          </ContainerModal>
        </Modal>
      </Background>
    </React.Fragment>
  )
}

export default TournamentConfirm
