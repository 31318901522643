import { useState } from 'react'
import { Row, Col, Drawer } from 'antd'
import { withTranslation } from 'react-i18next'
import Logo from '../../assets/logo.svg'
import { Button } from '../Button'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  ContainerMenu,
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  LinkNav,
  Span,
} from './styles'

interface HeaderProps {
  eventsLink?: string
  display?: string
}

const Header = ({ eventsLink, display }: HeaderProps) => {
  const [visible, setVisibility] = useState(false)
  const authenticatedUser = sessionStorage.getItem('@token')
  let navigate = useNavigate()

  const showDrawer = () => {
    setVisibility(!visible)
  }

  const onClose = () => {
    setVisibility(!visible)
  }

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement
      element.scrollIntoView({
        behavior: 'smooth',
      })
      setVisibility(false)
    }
    return (
      <ContainerMenu>
        <CustomNavLinkSmall>
          <LinkNav href="/">Bola no Ar</LinkNav>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <LinkNav
            href="https://loja.emaltabrasil.com.br/emaltabrasil"
            target="_blank"
          >
            Loja
          </LinkNav>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <LinkNav href={eventsLink}>Eventos</LinkNav>
        </CustomNavLinkSmall>
        {!authenticatedUser ? (
          <>
            <CustomNavLinkSmall style={{ width: '120px' }}>
              <Span>
                <Button onClick={() => navigate('/registration')}>
                  Cadastre-se
                </Button>
              </Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall style={{ width: '120px' }}>
              <Span>
                <Button
                  onClick={() => navigate('/login')}
                  color="#E9B219"
                  borderColor="#E9B219"
                >
                  Login
                </Button>
              </Span>
            </CustomNavLinkSmall>
          </>
        ) : (
          <>
            <CustomNavLinkSmall style={{ width: '180px' }}>
              <Span>
                <Button
                  onClick={() => {
                    navigate('/tournament-registration')
                  }}
                >
                  Criar Campeonato
                </Button>
              </Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall style={{ width: '120px' }}>
              <Span>
                <Button
                  onClick={() => {
                    sessionStorage.removeItem('@token')
                    navigate('/')
                  }}
                  color="#E9B219"
                  borderColor="#E9B219"
                >
                  Sair
                </Button>
              </Span>
            </CustomNavLinkSmall>
          </>
        )}
      </ContainerMenu>
    )
  }

  return (
    <HeaderSection>
      <Container display={display}>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img width="101px" height="64px" src={Logo} alt="Logo Altinha" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  )
}

export default withTranslation()(Header)
